// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Services
import subscriptionService from '../../services/subscriptionService';

// Import Components
import UpgradeMembership from '../Modals/upgradeMembership.js';
import FreeTrial from '../Modals/freeTrial.js';
import PaymentDue from '../Modals/paymentDue.js';

// Context
import { UserContext } from '../../context/User';

const UserSubscription = ({ userDetails }) => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [trialModalIsOpen, setTrialModalIsOpen] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [activeSubscriptions, setActiveSubscriptions] = useState([
    { start_date: '', next_recurring: '', type: '', frequency: 'Monthly', price: 0 },
  ]);
  const [purchaseSubModalIsOpen, setPurchaseSubModalIsOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState({
    start_date: '',
    next_recurring: '',
    type: '',
    frequency: 'Monthly',
    price: 0,
  });
  const [paymentDueIsOpen, setPaymentDueIsOpen] = useState(false);

  let useUser = userDetails ? userDetails : user;

  function closeMembershipModal(goToFreeTrial) {
    setModalIsOpen(false);
    if (goToFreeTrial && !userDetails) {
      setTrialModalIsOpen(true);
    } else if (userDetails) {
      getSubscription();
    }
  }

  function closeTrialModal() {
    setTrialModalIsOpen(false);
    window.location.reload();
  }

  /*
  const changeSubscription = async () => {
    if (useUser.level === 'free' || useUser.level === 'freeMaxed') {
      // open modal to upgrade
    }
  };
*/

  async function getSubscription() {
    if (useUser.id) {
      let subDetail = await subscriptionService.getUserSubscriptionDetail(useUser.id);

      for (let i = 0; i < subDetail.length; i++) {
        if (subDetail[i] && subDetail[i].coupon) {
          if (subDetail[i].coupon.type === 'percent') {
            subDetail[i].couponValue = subDetail[i].price * (subDetail[i].coupon.discount / 100);
          } else if (subDetail[i].coupon.type === 'fixed') {
            subDetail[i].couponValue = subDetail[i].coupon.discount;
          }
          if (subDetail[i].couponValue) {
            subDetail[i].couponPrice = subDetail[i].price - subDetail[i].couponValue;
          }
        }
      }

      setActiveSubscriptions(subDetail);

      if (subDetail && subDetail.length > 0) {
        if (subDetail.length === 1) {
          setCurrentSubscription(subDetail[0]);
        } else {
          for (let i = 0; i < subDetail.length; i++) {
            if (!subDetail[i].end_date) {
              setCurrentSubscription(subDetail[i]);
            }
          }
        }
      }
    }
  }

  async function addPaymentInfo() {
    setPaymentDueIsOpen(true);
  }

  /*
  function closePurchaseSubModal() {
    setPurchaseSubModalIsOpen(false);
    window.location.reload();
  }
  */

  useEffect(() => {
    getSubscription();
  }, [useUser]);

  return (
    <>
      <PaymentDue modalIsOpen={paymentDueIsOpen} setModalIsOpen={setPaymentDueIsOpen} />
      <UpgradeMembership
        isOpen={modalIsOpen}
        onRequestClose={closeMembershipModal}
        includeFree={false}
        userDetails={useUser}
        currentSubscription={currentSubscription}
      />
      <FreeTrial isOpen={trialModalIsOpen} onRequestClose={closeTrialModal} />
      {activeSubscriptions &&
        activeSubscriptions.length > 0 &&
        activeSubscriptions.map((subscription, index) => (
          <React.Fragment key={index}>
            <div className="card" style={{ minWidth: '300px' }}>
              <div style={{ padding: '10px' }}>
                <div className="cardTitle fullWidthContainer">
                  {subscription.start_date === subscription.next_recurring ? (
                    <span className="OnePortionFlex">
                      {`Upcoming Plan: ${
                        subscription.type && subscription.type.length > 0
                          ? subscription.type.charAt(0).toUpperCase() + subscription.type.slice(1)
                          : 'Free'
                      }`}
                    </span>
                  ) : (
                    <span className="OnePortionFlex">
                      {`Current Plan: ${
                        subscription.type && subscription.type.length > 0
                          ? subscription.type.charAt(0).toUpperCase() + subscription.type.slice(1)
                          : 'Free'
                      }`}
                    </span>
                  )}
                  <span className="OnePortionFlex"></span>
                  {subscription.id === currentSubscription.id ? (
                    <button
                      id="changeSubscriptionProfile"
                      className="OnePortionFlex buttonClone"
                      onClick={() => setModalIsOpen(true)}
                    >
                      Change
                    </button>
                  ) : null}
                </div>

                <div className="FullWidthFlex">
                  <span className="OnePortionFlex Padded">Renewal Frequency </span>
                  <span className="TwpPortionFlex Padded">{subscription.frequency}</span>
                </div>
                {useUser && useUser.invoiceDue && useUser.invoiceDue.length > 0 ? (
                  <div className="FullWidthFlex">
                    <span className="OnePortionFlex Padded orangeText bold">
                      Payment Due for Renewal{' '}
                    </span>
                    <span className="TwpPortionFlex Padded">
                      <span className="bold purpleText linkText" onClick={() => addPaymentInfo()}>
                        Update Payment Information
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="FullWidthFlex">
                    <span className="OnePortionFlex Padded">Next Renewal Date </span>
                    <span className="TwpPortionFlex Padded">
                      {subscription.next_recurring && !subscription.end_date
                        ? new Date(subscription.next_recurring).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })
                        : 'N/A'}
                    </span>
                  </div>
                )}
                <div className="FullWidthFlex">
                  <span className="OnePortionFlex Padded">Price </span>
                  <span className="TwpPortionFlex Padded">
                    {subscription && subscription.coupon
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(subscription.couponPrice)
                      : subscription
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(subscription.price)
                      : 'Free'}
                  </span>
                </div>
                {subscription && subscription.coupon ? (
                  <div className="FullWidthFlex">
                    <span className="ThreePortionFlex purpleText" style={{ textAlign: 'center' }}>
                      Includes
                      {subscription.coupon.type === 'percent'
                        ? ' ' + subscription.coupon.discount + '% '
                        : ' $' + subscription.coupon.discount + ' '}
                      off promotion
                    </span>
                  </div>
                ) : null}
                {subscription.end_date ? (
                  <div className="FullWidthFlex">
                    <span className="OnePortionFlex Padded">
                      {subscription.end_date ? 'Ends on' : null}{' '}
                    </span>
                    <span className="TwpPortionFlex Padded">
                      {subscription.end_date
                        ? new Date(subscription.end_date).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })
                        : null}
                    </span>
                  </div>
                ) : null}
                <div className="fullWidthContainer">
                  <p className="errorText">{error}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

export default UserSubscription;
