// Import Libraries
import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import {
  CircularProgress,
  Typography,
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

// Services
import AdminUserService from '../../services/adminUserService';

const UserStatistics = () => {
  const [error, setError] = useState(null);
  const [statData, setStatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [interval, setInterval] = useState('daily'); // State for interval selection

  // State for Date Pickers
  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'month'));
  const [endDate, setEndDate] = useState(dayjs());

  // Fetch user stats when date range or interval changes
  useEffect(() => {
    const getUserStats = async () => {
      try {
        setLoading(true);
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');

        const response = await AdminUserService.getUserStatData(
          formattedStartDate,
          formattedEndDate
        );
        console.log('User Stats:', response);
        setStatData(response);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user stats:', err);
        setError('Failed to load user statistics.');
        setLoading(false);
      }
    };

    getUserStats();
  }, [startDate, endDate, interval]); // Trigger fetch when date/interval changes

  // Function to generate all required date keys for consistency
  const generateDateRange = (start, end) => {
    let currentDate = dayjs(start);
    const dateArray = [];

    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      if (interval === 'daily') {
        dateArray.push(currentDate.format('MM-DD-YY'));
        currentDate = currentDate.add(1, 'day');
      } else if (interval === 'weekly') {
        const weekStart = currentDate.startOf('week').format('MM-DD-YY');
        const weekEnd = currentDate.endOf('week').format('MM-DD-YY');
        dateArray.push(`${weekStart} to ${weekEnd}`);
        currentDate = currentDate.add(1, 'week');
      } else if (interval === 'monthly') {
        const monthStart = currentDate.startOf('month').format('MM-YYYY');
        const monthEnd = currentDate.endOf('month').format('MM-DD-YY');
        dateArray.push(`${monthStart} to ${monthEnd}`);
        currentDate = currentDate.add(1, 'month');
      }
    }
    return dateArray;
  };

  // Function to group data based on selected interval
  const groupDataByInterval = (data, intervalType) => {
    const groupedData = new Map();
    const tooltipRanges = new Map();

    data.forEach((entry) => {
      let dateKey;
      let tooltipKey;
      const date = dayjs(entry.login_date);

      if (intervalType === 'daily') {
        dateKey = date.format('MM-DD-YY');
        tooltipKey = dateKey;
      } else if (intervalType === 'weekly') {
        const weekStart = date.startOf('week').format('MM-DD-YY');
        const weekEnd = date.endOf('week').format('MM-DD-YY');
        dateKey = `${weekStart} to ${weekEnd}`;
        tooltipKey = `${weekStart} to ${weekEnd}`;
      } else if (intervalType === 'monthly') {
        const monthStart = date.startOf('month').format('MM-YYYY');
        const monthEnd = date.endOf('month').format('MM-DD-YY');
        dateKey = `${monthStart} to ${monthEnd}`;
        tooltipKey = `${monthStart} to ${monthEnd}`;
      }

      if (!groupedData.has(dateKey)) {
        groupedData.set(dateKey, 0);
        tooltipRanges.set(dateKey, tooltipKey);
      }
      groupedData.set(dateKey, groupedData.get(dateKey) + entry.unique_users);
    });

    return { groupedData, tooltipRanges };
  };

  // Generate full date range and ensure missing data defaults to 0
  const fullDates = generateDateRange(startDate, endDate);
  const { groupedData, tooltipRanges } = groupDataByInterval(statData, interval);

  // Fill missing dates with 0
  const formattedLogins = fullDates.map((date) => groupedData.get(date) ?? 0);

  return (
    <Box sx={{ width: '100%', textAlign: 'center', padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        User Login Statistics
      </Typography>

      {/* Date Range Selection & Interval Selection */}
      <Box display="flex" justifyContent="center" gap={2} mb={3}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate} // Auto-update
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={setEndDate} // Auto-update
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Box>

      {/* Interval Selection Dropdown */}
      <FormControl sx={{ minWidth: 150, mb: 3 }}>
        <InputLabel>Interval</InputLabel>
        <Select
          value={interval}
          onChange={(e) => setInterval(e.target.value)} // Auto-update
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
        </Select>
      </FormControl>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <LineChart
          xAxis={[{ scaleType: 'point', data: fullDates }]}
          yAxis={[
            {
              min: 0, // Ensure y-axis starts from 0
              tickFormatter: (value) => Math.round(value), // Display whole numbers
              tickInterval: 1, // Ensure whole number intervals
            },
          ]}
          series={[
            {
              data: formattedLogins,
              label: 'Unique Logins',
              color: 'blue',
              tooltip: fullDates.map((date) => tooltipRanges.get(date) || date), // Use tooltip date range
            },
          ]}
          width={800}
          height={400}
        />
      )}
    </Box>
  );
};

export default UserStatistics;
