// Import Libraries
import React, { useState } from 'react';
import { Typography, Select, MenuItem, Box } from '@mui/material';

// Import Components
import AdminUserDataGrid from './userDataGrid';
import AdminUserStatistics from '../components/user/userStatistics';

function AdminUserPage() {
  const [selectedView, setSelectedView] = useState('User Information');

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
  };

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Admin User Page</Typography>
        <Select value={selectedView} onChange={handleViewChange} size="small">
          <MenuItem value="User Information">User Information</MenuItem>
          <MenuItem value="User Statistics">User Statistics</MenuItem>
          <MenuItem value="User Charts">User Charts</MenuItem>
        </Select>
      </Box>
      {selectedView !== 'User Charts' ? (
        <AdminUserDataGrid selectedView={selectedView} />
      ) : (
        <AdminUserStatistics />
      )}
    </div>
  );
}

export default AdminUserPage;
