import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlusCircle, faMinusCircle, faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

// Import Services
import AdminSubSvc from '../../admin/services/adminSubscriptionService';
import AdminUtils from '../../admin/services/utils';
import subscriptionSvc from '../../services/subscriptionService';
import AddCreditsModal from '../../admin/components/subscriptions/addCreditsModal';
import SubtractCreditsModal from '../../admin/components/subscriptions/subtractCredits';

Modal.setAppElement('#root');

const ManageCreditsModal = ({ user, isOpen, onRequestClose }) => {
  const [credits, setCredits] = useState([]);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [showModal, setShowModal] = useState('main');
  const [selectedCredit, setSelectedCredit] = useState(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast(`Copied ID: ${text}`);
    });
  };

  const goBackToMain = () => {
    setShowModal('main');
    setSelectedCredit(null);
    setupCredits();
  };

  const setupCredits = async () => {
    try {
      const userCredits = await subscriptionSvc.fetchAvailableCredits(user.id);
      setAvailableCredits(userCredits);

      const getCredits = await AdminSubSvc.getAllCreditUsage(null, user.id);
      const processedCredits = getCredits.map((credit, index) => ({
        ...credit,
        uniqueId: `${credit.id}-${index}`,
      }));

      setCredits(processedCredits || []);
    } catch (error) {
      console.error('Error fetching credit usage:', error);
    }
  };

  useEffect(() => {
    if (!user) return;
    setupCredits();
  }, [user]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 30,
    },
    {
      field: 'copy',
      headerName: 'Copy',
      width: 50,
      renderCell: (params) => (
        <FontAwesomeIcon
          className="linkText"
          icon={faCopy}
          style={{ cursor: 'pointer' }}
          onClick={() => copyToClipboard(params.row.id)}
        />
      ),
    },
    { field: 'status', headerName: 'Status', width: 100, headerClassName: 'word-wrap-header' },
    {
      field: 'available_on',
      headerName: 'Available On',
      width: 150,
      headerClassName: 'word-wrap-header',
      renderCell: (params) => (params.value ? AdminUtils.FormatDateWithTime(params.value) : 'N/A'),
    },
    {
      field: 'must_use_by',
      headerName: 'Use By',
      width: 150,
      headerClassName: 'word-wrap-header',
      renderCell: (params) => (params.value ? AdminUtils.FormatDateWithTime(params.value) : 'N/A'),
    },
    {
      field: 'allowance',
      headerName: 'Starting Balance',
      width: 100,
      headerClassName: 'word-wrap-header',
    },
    {
      field: 'creditsUsed',
      headerName: 'Credits Used',
      width: 100,
      headerClassName: 'word-wrap-header',
      renderCell: (params) => (
        <span className="linkText" onClick={() => setSelectedCredit(params.row)}>
          {parseInt(params.value, 10) > 0 ? params.value : '0'}
        </span>
      ),
    },
    {
      field: 'invoice',
      headerName: 'Invoice',
      width: 100,
    },
    {
      field: 'currentBalance',
      headerName: 'Available',
      width: 100,
      headerClassName: 'word-wrap-header',
      renderCell: (params) => {
        const allowance = params?.row?.allowance || 0;
        const creditsUsed = parseInt(params?.row?.creditsUsed, 10) || 0;
        return allowance - creditsUsed;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) =>
        params?.row ? (
          <FontAwesomeIcon
            className="linkText orangeText"
            icon={faMinusCircle}
            onClick={() => {
              setSelectedCredit(params.row);
              setShowModal('subtract');
            }}
          />
        ) : null,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Manage User Credits"
      className="modal wide"
    >
      {/* Add & Subtract Modals */}
      <AddCreditsModal
        processUser={user}
        isModalOpen={showModal === 'add'}
        setModalIsOpen={() => goBackToMain()}
      />
      <SubtractCreditsModal
        processUser={user}
        allowance={selectedCredit}
        isModalOpen={showModal === 'subtract'}
        setModalIsOpen={() => goBackToMain()}
      />

      {/* Close Button */}
      <div className="close-button" role="button" onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      {/* Modal Header */}
      <div className="modalHeader bold">
        User Credits for {user?.id} ({user?.first_name} {user?.last_name}: {user?.email})
        <FontAwesomeIcon
          className="linkText large"
          icon={faPlusCircle}
          onClick={() => setShowModal('add')}
        />
      </div>

      <div className="modalContent">
        {/* MUI DataGrid for Credits */}
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={credits}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            disableSelectionOnClick
            getRowId={(row) => row.uniqueId} // Ensures rows are uniquely identified
          />
        </div>

        {/* Credit Usage Details */}
        {selectedCredit && (
          <div className="Padded">
            <h4>Credit Usage Details for Credit ID: {selectedCredit.id}</h4>
            {selectedCredit.usage && selectedCredit.usage.length > 0 ? (
              selectedCredit.usage.map((use) => (
                <div key={use.id} className="Padded">
                  {use.ref_type === 'script' ? (
                    <span>
                      Used {use.credits_used} credits on {AdminUtils.FormatDate(use.date)} for{' '}
                      {use.ref_type} {use.ref_id} by user ID: {use.used_by}
                    </span>
                  ) : (
                    <span>
                      User {use.used_by} removed {use.credits_used} credits on{' '}
                      {AdminUtils.FormatDate(use.date)}
                    </span>
                  )}
                </div>
              ))
            ) : (
              <div>No usage details found.</div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ManageCreditsModal;
